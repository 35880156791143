import { Ref, ref } from 'vue';
import { $loading, $api } from '@/api/index';
import router from '@/router/index';
import { toast } from '@/utils/toast';

export interface AssignInfo {
    assignKey1: string;
    sicdCode: string;
    sicdName: string;
    officeCode: string;
    officeName: string;
    deliveryCode: string;
    deliveryName: string;
    itnmCode: string;
    itnmnameS: string;
    dplaceName: number;
    saUcode: string;
    saUname: string;
    assignQunt: number;
    ton1: number;
    outQunt: number;
    notQunt: number;
    remark: string;
    sq: string;
    errChk: string;
    txtMsg: string;
    assignDate: string;
}

export interface OfficeList{
  OfficeName: string;
  OfficeCode: string;
}

export function assigns(){

    const isConnected = ref(true);
    const assignlist: Ref<AssignInfo[]> = ref([]);
    const officelist: Ref<OfficeList[]> = ref([]);
    

    async function fetchDatas(frDate: string, sicdCode: string, officeCode: string) {
        await $loading(`/assgin?frDate=${frDate}&sicdCode=${sicdCode}&officeCode=${officeCode}`, 'POST').then(
          (res: any) => {
            if(res.data == ''){
              assignlist.value = [];
              toast('데이터가 존재하지 않습니다.'); 
            }else{
              if(res.data[0].errChk == -1){
                toast(res.data[0].txtMsg); 
              }else{
                assignlist.value = null;
                assignlist.value = res.data;
              }
              
            }
            assignlist.value = res.data;
          }
        );
      }

      async function fetchOfficeDatas() {
        await $api(`/officeinfo`, 'GET').then(
          (res: any) => {
            officelist.value = res.data;
          }
        );
      }

      async function modifyAssign(assignKey1: string, ton: string, id: string, siCode: string, remark: string) {
        await $loading(`/modifyassign?assignKey1=${assignKey1}&ton=${ton}&id=${id}&siCode=${siCode}&remark=${remark}`
                        , 'POST').then(
          (res: any) => {
            if(res.status == 200){
              toast('수정되었습니다.'); 
              router.replace('/chulhaassign');
            }
          }
        );
      }

      async function deleteAssign(assignKey1: string, siCode: string, id: string) {
        await $loading(`/deleteassign?assignKey1=${assignKey1}&siCode=${siCode}&id=${id}`
                        , 'POST').then(
          (res: any) => {
            if(res.data[0].errChk == -1 || res.data[0].errChk == -2){
              toast(res.data[0].txtMsg);
            }
            else{
              toast(res.data[0].txtMsg);
              router.replace('/chulhaassign');
            }
          }
        );
      }
    
    return { isConnected, assignlist, fetchDatas, modifyAssign, deleteAssign, fetchOfficeDatas, officelist};
}