<template>
    <ion-page>
        <app-header back="ChulhaAssign" title="출하배정수정" />
        <ion-content :fullscreen="true">
            <template v-if="isConnected">
                <div id="container" class="text-align-center">
                    <ion-grid style="text-align: left;">
                        <ion-row class="ion-justify-content-left">
                            <ion-col size-xl="1.5" size-md="1.5" size-xs="3"
                                style="margin-top: auto; margin-bottom: auto;">
                                <span>출하일자</span>
                            </ion-col>
                            <ion-col size-xl="2" size-md="2" size-xs="5">
                                <ion-input class="calendar" :value="saleinfo.assigndate" id="frdate" readonly><ion-icon
                                        :icon="calendar"></ion-icon></ion-input>
                            </ion-col>
                            <ion-col size-xl="3" size-md="3" size-xs="4" style="text-align: center; margin: auto;">
                                {{ sicd.name }}
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                    <ion-list>
                        <ion-item>
                            <span>착지</span>
                            <ion-label class="info">{{ dplacenm.name }}</ion-label>
                        </ion-item>
                        <ion-item>
                            <ion-label>영업소</ion-label>
                            <ion-label class="info">{{ saleinfo.officename }}</ion-label>
                        </ion-item>
                        <ion-item>
                            <span>청구처</span>
                            <ion-label class="info">{{ saleinfo.salesname }}</ion-label>
                        </ion-item>
                        <ion-item>
                            <ion-label>사업자번호</ion-label>
                            <ion-label class="info">{{ saleinfo.salesano }}</ion-label>
                        </ion-item>
                        <ion-item>
                            <ion-label>대표자</ion-label>
                            <ion-label class="info">{{ saleinfo.saleower }}</ion-label>
                        </ion-item>
                        <ion-item>
                            <span>주문유형</span>
                            <ion-label class="info" style="font-size: 13px;">{{ saleinfo.ordername }}</ion-label>
                        </ion-item>
                        <ion-item>
                            <span>인도조건</span>
                            <ion-label class="info" style="font-size: 13px;">{{ saleinfo.deliname }}</ion-label>
                        </ion-item>
                        <ion-item>
                            <span>품목</span>
                            <ion-label class="info" style="font-size: 13px;">{{ saleinfo.itnmname }}</ion-label>
                        </ion-item>
                        <ion-item v-if="sicd.name === '인천공장'">
                            <span>운송사</span>
                            <ion-label class="info" style="font-size: 13px;">{{ saleinfo.sauname }}</ion-label>
                        </ion-item>
                        <ion-item>
                            <ion-label>배정가능수량</ion-label>
                            <ion-label class="info">{{
                                quntinfo.qunt.toLocaleString('ko-KR', { minimumFractionDigits: 2 })
                            }}</ion-label>
                        </ion-item>
                        <ion-item class="item-has-focus ion-touched ion-invalid">
                            <ion-label>배정</ion-label>
                            <ion-input id="ton" type="text" style="text-align: right" placeholder="수량을 입력하세요"
                                @input="bindton" :value="quntinfo.ton"></ion-input>
                        </ion-item>
                        <ion-item>
                            <ion-label class="ion-margin-vertical">비고</ion-label>
                            <ion-textarea id="remark" class="ion-no-padding" style="text-align: right"></ion-textarea>
                        </ion-item>
                    </ion-list>
                </div>
                <ion-grid>
                    <ion-row class="ion-justify-content-center">
                        <ion-col size-md="2" size-xs="6">
                            <ion-button expand="block" @click="sendModi" color="medium">수정</ion-button>
                        </ion-col>
                        <ion-col size-md="2" size-xs="6">
                            <ion-button expand="block" color="danger" @click="delModi">삭제</ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </template>
            <template v-else>
                <network-error v-on:@onReConnect="onReConnect" />
            </template>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonContent,
    IonPage,
    IonItem,
    IonIcon,
    IonList,
    IonLabel,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    onIonViewWillEnter,
    IonTextarea
} from '@ionic/vue';
import { defineComponent, reactive, computed, } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router'
import { toast } from '@/utils/toast';
import { saleinfos } from '@/api/services/assignnew';
import { assigns } from '@/api/services/assign';
import { calendar } from 'ionicons/icons';
export default defineComponent({
    components: {
        IonContent,
        IonPage,
        IonItem,
        IonIcon,
        IonList,
        IonLabel,
        IonButton,
        IonGrid,
        IonRow,
        IonCol,
        IonInput,
        IonTextarea
    },
    setup() {
        const store = useStore();
        const auth = computed(() => store.getters['auth/getData']);
        const {
            isConnected,
            saleinfolist,
            fetchDatas,
            orderlist,
            deliverylist,
            itnmlist,
            salelist,
            fetchModQuntData,
            quntable,
        } = saleinfos();
        const { modifyAssign, deleteAssign } = assigns();

        const route = useRoute();
        const dplacenm = reactive({
            code: '',
            name: '',
        });

        const sicd = reactive({
            code: '',
            name: ''
        });

        const saleinfo = reactive({
            assignkey1: '',
            officename: '',
            salename: '',
            salesano: '',
            saleower: '',
            ordername: '',
            ordercode: '',
            delicode: '',
            deliname: '',
            itnmcode: '',
            itnmname: '',
            saucode: '',
            sauname: '',
            assigndate: '',
        });

        const quntinfo = reactive({
            qunt: '',
            ton: '',
        });

        onIonViewWillEnter(async () => {
            quntinfo.ton = route.params.qunt
            if (route.params.deplaceCode) {
                dplacenm.code = route.params.deplaceCode
                dplacenm.name = route.params.deplaceCode + ' / ' + route.params.deplaceName
                sicd.name = route.params.sicdName
                sicd.code = route.params.sicdCode
                saleinfo.ordername = route.params.orderCode + ' / ' + route.params.orderName
                saleinfo.deliname = route.params.deliveryCode + ' / ' + route.params.deliveryName
                saleinfo.delicode = route.params.deliveryCode
                saleinfo.itnmname = route.params.itnmCode + ' / ' + route.params.itnmNameS
                saleinfo.itnmcode = route.params.itnmCode
                saleinfo.assigndate = route.params.assignDate
                saleinfo.assigndate = saleinfo.assigndate.substring(0, 4) + '-' +
                    saleinfo.assigndate.substring(4, 6) + '-' +
                    saleinfo.assigndate.substring(6)
                document.querySelector('#remark').value = route.params.remark
                if (sicd.name == '부두공장') {
                    saleinfo.sauname = ''
                } else {
                    saleinfo.sauname = route.params.saUcode + ' / ' + route.params.saUname
                }
                saleinfo.assignkey1 = route.params.assignKey1

                await fetchDatas(dplacenm.code)
                saleinfo.officename = saleinfolist.value[0].officeName
                saleinfo.salesname = saleinfolist.value[0].saleName
                saleinfo.salesano = saleinfolist.value[0].saleSano.substring(0, 3) + '-' + saleinfolist.value[0].saleSano.substring(3, 5) + '-' + saleinfolist.value[0].saleSano.substring(5)
                saleinfo.saleower = saleinfolist.value[0].saleOwer

                await fetchModQuntData(saleinfo.assigndate.replaceAll('-', ''), saleinfo.delicode, dplacenm.code, saleinfo.itnmcode, sicd.code, saleinfo.assignkey1)
                quntinfo.qunt = quntable.value[0].qunt
            } else {
                dplacenm.name = '';
                saleinfo.ordername = '';
                saleinfo.deliname = '';
                saleinfo.itnmname = '';
                saleinfo.sauname = '';
                toast('데이터가 존재하지 않습니다.');
            }
        });

        function comma(str) {
            str = String(str);
            return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
        function uncomma(str) {
            str = String(str);
            return str.replace(/[^\d]+/g, '');
        }
        function bindton(event) {

            quntinfo.ton1 = Number(uncomma(event.target.value))
            quntinfo.ton = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            quntinfo.ton = comma(uncomma(event.target.value))
            quntinfo.calton = Number(uncomma(event.target.value))
            if (saleinfo.itnmcode == '200667') {
                quntinfo.ton1 = quntinfo.calton * 0.04
            }

        }

        function errChk() {
            if (dplacenm.code == '') {
                toast("출하배정현황으로 돌아가주세요.");
                return -1;
            }
            if (document.querySelector('#ton').value == '') {
                toast("수량을 입력해주세요.");
                return -1;
            }
            if (Number(quntinfo.qunt) - Number(uncomma(quntinfo.ton)) < 0) {
                toast("배정가능수량을 초과하였습니다.")
                return -1;
            }
            return 1;
        }

        async function sendModi() {
            const a = errChk();
            if (a == -1) {
                console.log("수정못함")
            } else {
                modifyAssign(
                    saleinfo.assignkey1,
                    document.querySelector('#ton').value.replaceAll(',', ''),
                    auth.value.id,
                    sicd.code,
                    document.querySelector('#remark').value
                )
            }

        }

        async function delModi() {
            deleteAssign(
                saleinfo.assignkey1,
                sicd.code,
                auth.value.id,
            )
        }

        return {
            calendar,
            sicd,
            isConnected,
            saleinfolist,
            dplacenm,
            saleinfo,
            orderlist,
            deliverylist,
            itnmlist,
            salelist,
            quntinfo,
            sendModi,
            bindton,
            delModi
        };
    }
});
</script>

<style scoped>
#container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

ion-item {
    --padding-start: 0px;
    --highlight-color-invalid: #3880ff;
    --highlight-height: 2px;
}

ion-item ion-label {
    padding-left: 10px;
    min-width: 4em;
    color: blue;
}

ion-item ion-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    overflow: hidden;
}

ion-item ion-input {
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    overflow: hidden;
}

ion-button {
    width: 100%;
    margin: 1.5em 0;
    height: 3em;
}

ion-skeleton-text {
    width: 30%;
}

#ton.item-has-focus {
    color: blue !important;
}

ion-select {
    position: relative;
}

ion-item span {
    padding-left: 10px;
}

.info {
    text-align: right;
}

ion-grid {
    padding-bottom: 0;
    padding-top: 0;
}

ion-list {
    padding: 0;
}
</style>